import $ from 'jquery';

import ChairishABTest from 'chairisher/abtest';

// TODO (CHAIR-16506) - Delete file once ab test is complete
export default class HorizontalNavBarTest extends ChairishABTest {
    static getExperimentName() {
        return 'HORIZONTAL_NAV_BAR_TEST';
    }

    variation() {
        $('body').addClass('horizontal-nav-bar-variant');
        $('#js-product-nav-horizontal').removeClass('hidden');
    }
}
