import $ from 'jquery';

import MediaQueryUtils from 'chairisher/util/mediaquery';

import { logAmplitudeLinkClickEvent, NavigationPosition } from 'chairisher/util/analytics';

/**
 * TODO (CHAIR-16506) - Remove once ab test is complete
 *
 * Track a click event on the product nav horizontal
 *
 * @param {Event} e The event fired
 */
export function trackProductNavigationHorizontalLinkClick(e) {
    const $link = $(e.currentTarget);
    const ctaName = `/${$link.text().trim()}`;

    logAmplitudeLinkClickEvent(ctaName, $link.attr('href'), NavigationPosition.PRODUCT_NAV_HORIZONTAL);
}

/**
 * Track a click event on the product nav
 *
 * @param {Event} e The event fired
 */
export function trackProductNavigationLinkClick(e) {
    const $link = $(e.currentTarget);

    const linkText = $link.text().trim();
    let parentText = null;
    let ctaName = null;

    if ($link.hasClass('js-initiator')) {
        if (MediaQueryUtils.isMaxMediumDesktop() || $link.attr('href') === '#') {
            return; // don't track an event when expanding mobile navigation or if href is '#'
        }

        ctaName = linkText;
    } else {
        parentText = $link.closest('.js-product-nav-item').children('.js-initiator').text().trim();
        ctaName = `${parentText}/${linkText}`;
    }

    logAmplitudeLinkClickEvent(ctaName, $link.attr('href'), NavigationPosition.PRODUCT_NAV);
}

/**
 * Track a click event on the cart icon
 *
 * @param {Event} e The event fired
 */
export function trackHeaderCartIconClick(e) {
    const $link = $(e.currentTarget);
    logAmplitudeLinkClickEvent('View Cart', $link.attr('href'), NavigationPosition.HEADER_CART_ICON);
}

/**
 * Track a click event on the discover designers cta on the cross site nav
 *
 * @param {Event} e The event fired
 */
export function trackDiscoverNavLinkClick(e) {
    const $link = $(e.currentTarget);
    logAmplitudeLinkClickEvent($link.text().trim(), $link.attr('href'), NavigationPosition.DESIGNER_DIRECTORY_CTA);
}

/**
 * Track a click event on the favorites drop down menu items
 *
 * @param {Event} e The event fired
 */
export function trackFavoritesMenuItem(e) {
    const $link = $(e.currentTarget);
    logAmplitudeLinkClickEvent(
        $link.find('.favorites-nav-link-titles').text(),
        $link.attr('href'),
        NavigationPosition.FAVORITES_DROP_DOWN_MENU,
    );
}

/**
 * Tracks a `link - click` event for the Message of the Day (MOTD) banner
 *
 * @param {Event} e The event fired
 */
export function trackMotdLinkClick(e) {
    const $link = $(e.currentTarget);
    logAmplitudeLinkClickEvent($link.text().trim(), $link.attr('href'), NavigationPosition.MOTD);
}
